<template>
  <div>
    <h3>DEFENSA DEL CONSUMIDOR</h3>
    <ul class="dotsLeft">
      <li>
        <h5 class="textAlignLeft">
          Defensa de las y los Consumidores. Para reclamos ingrese
          <a
            target="_blank"
            href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario"
            >aquí</a
          >
        </h5>
      </li>
      <li>
        <h5 class="textAlignLeft">
          Dónde y cómo reclamar si residís en la CABA. Clickea
          <a
            target="_blank"
            href="https://www.buenosaires.gob.ar/defensaconsumidor/como-denunciar"
            >acá</a
          >
        </h5>
      </li>
      <li>
        <h5 class="textAlignLeft">
          Consultá a Ley de Defensa del consumidor, ingresando
          <a
            target="_blank"
            href="https://www.argentina.gob.ar/justicia/derechofacil/leysimple/defensa-del-consumidor"
            >acá</a
          >
        </h5>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ConsumerDefense",
  methods: {},
};
</script>


<style scoped>
.textAlignLeft {
  text-align: left;
  margin-left: 15px;
}
.dotsLeft {
  margin-left: 40px;
}
</style>
